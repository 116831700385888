export const BASE_URL = "https://portoapicloud-hml.portoseguro.com.br";
export const AUTHORIZATION =
  "Basic ZTkyZjgzZmItZjllNi00ZTBjLWE3ZjEtYmY0YTc2YzNmNTgzOjQ2MGZlODJjLTM4ODktNGM4MS1hNjE5LTU3ZGFmMTRmZDcyZA==";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAtfzZAfwdOudBAbLwdBxCxasQbANkDQbE",
  authDomain: "consorcio-hml.firebaseapp.com",
  databaseURL: "https://consorcio-hml.firebaseio.com",
  projectId: "consorcio-hml",
  storageBucket: "consorcio-hml.appspot.com",
  messagingSenderId: "333993014506",
  appId: "1:333993014506:web:57c42501e21a113271d393",
};

export const CAPTCHA = "6Lc6QqQZAAAAAJlcWgqLHFK4VBe357jUsQeKd09B";
