import React, { useState } from "react";
import { useSelector } from "react-redux";

import { formatCurrency } from "helpers/currency";

import { Icons } from "components";
import ModalCoupon from "../modalCoupon";

import { Container } from "./styles";

function Table() {
  const { data } = useSelector(state => state.weblink);
  const [coupon, setCoupon] = useState("");
  const [openModal, setOpenModal] = useState(false);

  function handleToggleModal() {
    setOpenModal(!openModal);
  }

  async function handleGetCoupon(couponName) {
    await new Promise(resolve => {
      setTimeout(() => {
        console.log("resolved...");
        setCoupon(couponName);
        setOpenModal(false);
        resolve(true);
      }, 1000);
    });
  }

  return (
    <>
      <ModalCoupon
        open={openModal}
        handleClose={handleToggleModal}
        handleGetCoupon={handleGetCoupon}
      />
      <Container config={data?.config}>
        <thead>
          {data?.config?.items_columns ? (
            <tr>
              <th>{data?.config?.items_columns?.item}</th>
              {data?.config?.items_columns?.quantity ? (
                <th width="50">{data?.config?.items_columns?.quantity}</th>
              ) : (
                ""
              )}
              {data?.config?.items_columns?.value ? (
                <th width="150">{data?.config?.items_columns?.value}</th>
              ) : (
                ""
              )}
            </tr>
          ) : (
            <tr>
              <th>Produto</th>
              <th width="50">Qtd.</th>
              <th width="150">Preço</th>
            </tr>
          )}
        </thead>
        <tbody>
          {data.items &&
            data.items.map((item, key) => (
              <tr key={key}>
                <td>{item.name}</td>
                {data?.config?.items_columns?.quantity ? (
                  <td>{item.quantity}</td>
                ) : (
                  ""
                )}
                {data?.config?.items_columns?.value ? (
                  <td>R$ {item.amount ? formatCurrency(item.amount) : "--"}</td>
                ) : (
                  ""
                )}
              </tr>
            ))}
        </tbody>
        {data.prices?.others?.amount ? (
          <tfoot>
            <tr>
              <td colSpan="2">
                {data?.config?.coupon && (
                  <>
                    {!coupon && (
                      <p data-highlight onClick={handleToggleModal}>
                        <Icons name="tag" />
                        Possui um cupom de desconto? Clique aqui.
                      </p>
                    )}

                    {coupon && (
                      <p data-coupon onClick={handleToggleModal}>
                        Cupom adicionado:
                        <span>{coupon}</span>
                      </p>
                    )}
                  </>
                )}
              </td>
              <td>
                <small>Subtotal</small>
                <p data-price>
                  R$ {formatCurrency(data.prices?.others?.amount)}
                </p>
              </td>
            </tr>
          </tfoot>
        ) : (
          ""
        )}
      </Container>
    </>
  );
}

export default Table;
