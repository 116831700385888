export const BASE_URL = "https://portoapicloud.portoseguro.com.br";
export const AUTHORIZATION =
  "Basic MWFiZTg1YTktMjE3Ni00YTA3LWIyZGMtNDIyMDYzNTJhMDJkOjU5NGQxZTc1LTU0MzItNDBlNC05MDcxLWRhODQ2MjBmMGJiNw==";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCpk3TtCt0dgLYZzTAjrDIbx1wA-RwZscs",
  authDomain: "consorcio-prd.firebaseapp.com",
  databaseURL: "https://consorcio-prd.firebaseio.com",
  projectId: "consorcio-prd",
  storageBucket: "consorcio-prd.appspot.com",
  messagingSenderId: "991150749082",
  appId: "1:991150749082:web:c39dc312592fbe45087623",
};

export const CAPTCHA = "6LeIhqYZAAAAAI5b0pV25d-OBGsmKgnljO27uyV8";
