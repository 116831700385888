import axios from "axios";
import config from "config";
import qs from "qs";

export const api = axios.create({
  baseURL: config.BASE_URL,
});
const AUTHORIZATION = config.AUTHORIZATION

api.interceptors.request.use(async (config) => {
  if (config.url.endsWith("/oauth/v2/access-token")) return config;
  const response = await api.post(
    "/oauth/v2/access-token",
    qs.stringify({
      grant_type: "client_credentials",
    }),
    {
      headers: {
        Authorization:
        AUTHORIZATION,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  config.headers[
    "Authorization"
  ] = `${response.data.token_type} ${response.data.access_token}`;

  return config;
});

export function postPaymentWeblink(payload, captcha) {
  return api.post("/consorcio/pagamento/v1/payment", payload, {
    headers: { captcha },
  });
}

export function getValidationWeblink(uid) {
  return api.get(`/consorcio/pagamento/v1/firebaseapp/web-links/${uid}`);
}
